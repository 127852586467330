<template>
  <vx-card>
    <form-wizard
        color="rgba(var(--vs-primary), 1)"
        :title="title"
        :subtitle="subTitle"
        :hide-buttons="true"
    >
      <div class="vx-row">
        <vs-divider class="vx-col w-full mt-5" position="left" color="dark">Información de notificación de
          {{ module }}
        </vs-divider>
        <div class="vx-col md:w-1/2 w-full">
          <label for="recipient" class="w-full select-large">Destinatario</label>
          <vs-input
              id="recipient"
              name="recipient"
              v-model="notificationSettingsModel.recipient"
              v-validate="'required|email'"
              class="w-full"
              autocomplete="off"
          />
          <span class="text-danger">{{ errors.first("recipient") }}</span>
          <br/>
        </div>
      </div>

      <vs-divider class="vx-col w-full mt-5" position="left" color="dark">Sección de filtros módulo</vs-divider>

      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full mt-2">
          <label for="recipient" class="w-full select-large">Departamentos</label>
          <v-select
              multiple
              v-model="notificationSettingsModel.notifications_rules.entry.state"
              index="id"
              :options="states"
              :reduce="item => {return {id: item.id, name: item.name, slug: item.slug}}"
              label="name"
          />
        </div>
        <div class="vx-col md:w-1/2 w-full mt-2">
          <label for="recipient" class="w-full select-large">Ciudades</label>
          <v-select
              multiple
              v-model="notificationSettingsModel.notifications_rules.entry.city"
              index="id"
              :options="cities"
              :reduce="item => {return {id: item.id, name: item.name, slug: item.slug}}"
              label="name"
          />
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full mt-4">
          <label for="recipient" class="w-full select-large">Proyectos</label>
          <v-select
              multiple
              v-model="notificationSettingsModel.notifications_rules.entry.id"
              :options="entries"
              :reduce="item => item.id"
              label="name"
          />
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full mt-8">
          <div class="flex flex-wrap justify-between">
            <vs-row vs-type="flex" vs-justify="flex-end">
              <vs-button color="danger" type="border" class="mb-2" @click="goToBack">Cancelar</vs-button>
              <vs-button class="ml-3 mb-2" @click="createOrUpdateNotificationSettings" :disabled="!validateForm">
                Guardar
              </vs-button>
            </vs-row>
          </div>
        </div>
      </div>
    </form-wizard>
  </vx-card>
</template>

<script>
  import CoreClient from "../../utils/coreClient";
  import EntriesClient from "../../utils/entriesClient";
  import RealStateProjectsClient from "../../utils/realStateProjectsClient";
  import AttributeOptionsClient from "../../utils/attributeOptionsClient";
  import NotificationSettingsClient from "../../utils/notificationSettingsClient";
  import Notifier from "./../../utils/notification";
  import {FormWizard, TabContent} from "vue-form-wizard";
  import "vue-form-wizard/dist/vue-form-wizard.min.css";
  import "flatpickr/dist/flatpickr.css";
  import vSelect from "vue-select";
  import {Validator} from "vee-validate";

  let notifier = new Notifier();
  const NOTIFICATION_SETTINGS_CLIENT = new NotificationSettingsClient()
  const STATES_CLIENT = new CoreClient("states");
  const CITIES_CLIENT = new EntriesClient("cities");
  const ENTRIES_CLIENT = new RealStateProjectsClient();
  const ATTRIBUTE_OPTIONS_CLIENT = new AttributeOptionsClient("projects");

  const requiredFields = ["recipient"];

  let dict = requiredFields.reduce(
      (previousValue, currentValue) => {
        previousValue[currentValue] = {
          required: "* Este campo es requerido",
          email: "* Correo electrónico inválido"
        };
        return previousValue
      },
      {}
  );

  Validator.localize("es", {custom: dict});

  export default {
    data() {
      return {
        entryTypeList: {
          "assets": {
            "name": "Activos Eventuales",
            "entry_type": "assets"
          },
          "static-pages": {
            "name": "Páginas estáticas",
            "entry_type": "static-pages"
          },
          "jobs": {
            "name": "Plazas Disponibles",
            "entry_type": "jobs"
          },
          "promotions": {
            "name": "Promociones",
            "entry_type": "promotions"
          },
          "products": {
            "name": "Productos",
            "entry_type": "products"
          },
          "projects": {
            "name": "Proyectos Inmobiliarios",
            "entry_type": "projects"
          }
        },
        initialLoading: true,
        title: "",
        subTitle: "",
        module: "",
        entry_type: "",
        cities: [],
        states: [],
        entries: [],
        statesPrefilledList: [],
        notificationSettingsModel: {
          recipient: "",
          notifications_rules: {
            entry: {},
            form_data: {},
          }
        },
      };
    },

    watch: {
      'entryRules':  {
        handler(newVal, oldVal){
          if(!this.initialLoading){
            if(newVal.state !== oldVal.state){
              this.getCities(newVal.state, true);
            } else if (newVal.city !== oldVal.city) {
              this.getEntries(newVal.city, true);
            }
          }
        },
        deep: true,
      },
    },

    mounted() {
      if (this.$route.params.id !== undefined) {
        this.$vs.loading();
        this.getPageTitle(true);

        this.loadNotificationSettingData(this.$route.params.id).then(() => {
          this.$vs.loading.close();
          this.initialLoading = false;
        });

      } else {
        this.$vs.loading();
        this.getPageTitle(false);
        let promises = [
          this.getStates({showLoading: false}),
        ]
        Promise.all(promises).then(() => {
          this.$vs.loading.close();
          this.initialLoading = false;
        });
      }
    },

    computed: {
      validateForm() {
        return !this.errors.any()
            && requiredFields.reduce((accumulator, currentValue) => {
              return accumulator && this.notificationSettingsModel[currentValue] !== null;
            });
      },
      entryRules: function () {
        return Object.assign({}, this.notificationSettingsModel.notifications_rules.entry);
      }
    },

    methods: {
      getPageTitle(update) {
        let entry_type = "projects"
        let val = this.entryTypeList[entry_type]
        this.title = update ? `Editar destinatario de ${val.name}` : `Crear destinatario de ${val.name}`
        this.subTitle = "Configurar destinatario y los criterios para recibir notificaciones."
        this.module = val.name
        this.entry_type = val.entry_type
      },

      async getStates({showLoading = true} = {}) {
        if (showLoading) this.$vs.loading();

        await STATES_CLIENT.all()
            .then(response => {
              if (showLoading) this.$vs.loading.close();
              this.states = response.data;
            })
            .catch(error => {
              if (showLoading) this.$vs.loading.close();
              notifier.notification("error");
            });
      },

      async getCities(states, showLoading = true) {
        if (states !== null && states !== undefined && states.length > 0) {
          if (showLoading) this.$vs.loading();
          await CITIES_CLIENT.all({queryStrings: {state_id: states.map(x => x.id)}})
              .then(response => {
                response.data.forEach(el => el.name_code = `(${el.state_id}) ${el.name}`);
                response.data.sort((a,b) => (a.state_id > b.state_id) ? 1 : ((b.state_id > a.state_id) ? -1 : 0));

                if (this.notificationSettingsModel.notifications_rules.entry.city) {
                  let results = response.data.map(x => x.id);

                  this.notificationSettingsModel.notifications_rules.entry.city = this.notificationSettingsModel.notifications_rules.entry.city.filter(
                      x => results.includes(x.id)
                  );
                }

                this.cities = response.data;
                if (showLoading) this.$vs.loading.close();
              })
              .catch(error => {
                if (showLoading) this.$vs.loading.close();
                notifier.notification("error");
              });
        } else {
          this.cities = [];
          this.entries = [];
          delete this.notificationSettingsModel.notifications_rules.entry.state;
          delete this.notificationSettingsModel.notifications_rules.entry.city;
          delete this.notificationSettingsModel.notifications_rules.entry.id;
        }
      },

      async getEntries(cities, showLoading = true) {
        if (cities !== null && cities !== undefined && cities.length > 0) {
          if (showLoading) this.$vs.loading();

          await ENTRIES_CLIENT.list({
            queryStrings: {
              city: cities.map(x => x.slug),
              page_size: "all",
            }
          })
              .then(response => {
                if (this.notificationSettingsModel.notifications_rules.entry.id) {
                  let results = response.data.results.map(x => x.id);
                  this.notificationSettingsModel.notifications_rules.entry.id = this.notificationSettingsModel.notifications_rules.entry.id.filter(
                      x => results.includes(x)
                  );
                }
                this.entries = response.data.results;

                if (showLoading) this.$vs.loading.close();
              })
              .catch(error => {
                if (showLoading) this.$vs.loading.close();
                notifier.notification("error");
              });
        } else {
          this.entries = [];
          delete this.notificationSettingsModel.notifications_rules.entry.city;
          delete this.notificationSettingsModel.notifications_rules.entry.id;
        }
      },

      async loadNotificationSettingData(id) {
        await NOTIFICATION_SETTINGS_CLIENT.retrieve(this.entry_type, id)
            .then(response => {
              let entry_rules = response.data.notifications_rules.entry;
              let promises = [this.getStates({showLoading: false})];

              if (entry_rules.state) {
                promises.push(this.getCities(entry_rules.state, false));

                if (entry_rules.city) {
                  promises.push(this.getEntries(entry_rules.city, false));
                }
              }

              Promise.all(promises).then(() => {
                Object.assign(this.notificationSettingsModel, response.data)
              });
            })
            .catch(error => {
              notifier.notification("error");
            });
      },

      async createOrUpdateNotificationSettings() {
        let status_validation = true

        await this.$validator.validateAll().then(el => {
          status_validation = el
        })

        if(!status_validation) return false

        if (
            this.notificationSettingsModel.id !== null
            && this.notificationSettingsModel.id !== undefined
            && this.notificationSettingsModel.id !== ""
        ) {
          this.$vs.loading();
          this.cleanModel();

          await NOTIFICATION_SETTINGS_CLIENT.update(
              this.notificationSettingsModel, this.entry_type, this.$route.params.id
          )
              .then(response => {
                this.$vs.loading.close();
                notifier.notification("updated");
                this.goToBack();
              })
              .catch(error => {
                if(error.response.status === 403 &&  error.response.data.errors.recipient !== undefined){
                  this.errors.add({
                    field: 'recipient',
                    msg: `Ya existe un registro con el correo ${this.notificationSettingsModel.recipient} para ${this.module}`
                  })
                }

                this.$vs.loading.close();
                notifier.alertMessage("error");
              });
        } else {
          this.$vs.loading();
          this.cleanModel();

          await NOTIFICATION_SETTINGS_CLIENT.create(this.notificationSettingsModel, this.entry_type)
              .then(response => {
                this.$vs.loading.close();
                notifier.notification("created");
                this.goToBack();
              })
              .catch(error => {
                if(error.response.status === 403 &&  error.response.data.errors.recipient !== undefined){
                  this.errors.add({
                    field: 'recipient',
                    msg: `Ya existe un registro con el correo ${this.notificationSettingsModel.recipient} para ${this.module}`
                  })
                }
                this.$vs.loading.close();
                notifier.alertMessage("error");
              });
        }
      },

      goToBack() {
        this.$router.push({name: "notification-settings", params: {entry_type: this.entry_type}});
      },

      cleanModel() {
        let exclude = ["entry_type", "id", "unix_created_at", "unix_updated_at"]

        Object.keys(this.notificationSettingsModel).forEach(key => {
          if (exclude.includes(key)) {
            delete this.notificationSettingsModel[key];
          }
        })

        this.notificationSettingsModel.notifications_rules.entry = this.cleanNullKeys(
            this.notificationSettingsModel.notifications_rules.entry
        )

        this.notificationSettingsModel.notifications_rules.form_data = this.cleanNullKeys(
            this.notificationSettingsModel.notifications_rules.form_data
        )
      },

      cleanNullKeys(data){
        let newObject = {}

        try{
          for( let key in data ){
            if(data[key] !== null && data[key].length > 0){
              newObject[key] = data[key]
            }
          }
        } catch (error){
          console.log(error);
        }

        return newObject;
      },

      cleanArray(selected_items, response) {
        if (Array.isArray(selected_items)) {
          return selected_items.filter(el => { if (response.includes(el)) return el} )
        }
        return selected_items
      },
    },

    components: {
      FormWizard,
      TabContent,
      "v-select": vSelect,
    }
  };
</script>